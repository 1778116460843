import {type FC} from 'react';

import {Typography} from '../..';
import {RightArrowIcon} from '../../common/icons';

import {useEmotionWrapper} from './action-button.s';

interface IActionButton {
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const ActionButton: FC<IActionButton> = ({onClick, children, disabled, ...props}) => {
  const {classes} = useEmotionWrapper();
  return (
    <div
      className={classes.settingButton}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}
      {...props}
    >
      <Typography whiteSpace="nowrap">{children}</Typography>
      <RightArrowIcon />
    </div>
  );
};
